import { reducer as toastrReducer } from 'react-redux-toastr';
// import external parametreStructures
import { combineReducers } from 'redux';

import customizer from './customizer/';
import defaultReducer from './default';
import indicateurReducer from './indicateur';
import pageReducer from './page';
import parametreStructureReducer from './parametre-structure';
import moduleReducer from './module';
import menuReducer from './menu';
import agentReducer from './agent';
import userReducer from './user';
import iconReducer from './icon';
import campagneReducer from './campagne';
import documentationReducer from './documentation';
import livreReducer from './livre';
import mediaReducer from './media';
import contactReducer from './contact';
import aviReducer from './avis';
import typeAvisReducer from './typeAvis';
import messageReducer from './message';
import newsletterReducer from './newsletter';
import alertReducer from './alert';
import weblinkReducer from './weblink';
import partenaireReducer from './partenaire';
import actualiteReducer from './actualite';
import applicationReducer from './application';
import structureReducer from './structure';
import personReducer from './person';
import projectReducer from './project';
import rapportReducer from './rapport';
import gradeReducer from './grade';
import templateReducer from './template';
import categorieDocumentReducer from './categorieDocument';
import categorieLienReducer from './categorieLien';
import categoriePublicationReducer from './categoriePublication';
import typeAccesReducer from './typeAcces';
import categorieFaqReducer from './categorieFaq';
import domaineLivreReducer from './domaineLivre';
import FaqReducer from './Faq';
import groupeReducer from './groupe';
import corpReducer from './corp';
import qualiteReducer from './qualite';
import clientReducer from './client';

const rootReducer = combineReducers({
    toastr: toastrReducer, // <- Mounted at toastr.
    customizer: customizer,
    menu: menuReducer,
    indicateur: indicateurReducer,
    project: projectReducer,
    person: personReducer,
    structure: structureReducer,
    application: applicationReducer,
    actualite: actualiteReducer,
    partenaire: partenaireReducer,
    weblink: weblinkReducer,
    alert: alertReducer,
    newsletter: newsletterReducer,
    message: messageReducer,
    avi: aviReducer,
    typeAvis: typeAvisReducer,
    contact: contactReducer,
    media: mediaReducer,
    livre: livreReducer,
    documentation: documentationReducer,
    campagne: campagneReducer,
    page: pageReducer,
    template: templateReducer,
    corp: corpReducer,
    qualite: qualiteReducer,
    Faq: FaqReducer,
    groupe: groupeReducer,
    domaineLivre: domaineLivreReducer,
    categorieFaq: categorieFaqReducer,
    typeAcces: typeAccesReducer,
    categoriePublication: categoriePublicationReducer,
    categorieLien: categorieLienReducer,
    categorieDocument: categorieDocumentReducer,
    grade: gradeReducer,
    rapport: rapportReducer,
    module: moduleReducer,
    parametreStructure: parametreStructureReducer,
    default: defaultReducer,
    user: userReducer,
    client: clientReducer,
    icon: iconReducer,
    agent: agentReducer,
});

export default rootReducer;